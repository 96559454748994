@use '../../../../styles/config.scss';

.mobile-card{
	@include config.container($direction: column, $justify: flex-start);
	border-radius: 10px;
	height: 100%;
	width: 100%;
	background-color: white;
	overflow: hidden;
	.mobile-header {
		@include config.container($direction: column);
		text-align: center;
		width: 100%;
		height: 150px;
		background: linear-gradient(190deg, rgba(74,2,79,1) 0%, rgba(175,0,255,1) 51%, rgba(255,145,0,1) 100%);
		h4 {
			font-size: 30px;
			font-weight: 700;
			letter-spacing: 0.8px;
			line-height: 1.1em;
		}
		span {
			font-style: italic;
			font-weight: 400;
			font-size: 15px;
			letter-spacing: 0.8px;
		}
	}
	.mobile-content {
    @include config.container($direction: column, $align: flex-end);
    width: 100%;
    height: 100%;
		overflow: hidden;
    .mobile-details {
      @include config.container($direction: column, $justify: space-between);
      height: 100%;
			padding : 20px;
      .wave-divider {
        position: absolute;
        top: -20px;
      }
      p {
        width: 100%;
        margin: 0;
        color: black;
      }
    }
    .mobile-links {
      @include config.container($justify: space-around);
      opacity: 1;
      width: 100%;
			z-index: 100;
    }
  }
}

.project-links {
	opacity: 1;
}