// Small devices
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
			@content;
	}
}

// Medium devices
@mixin md {
	@media (min-width: #{$screen-md-min}) {
			@content;
	}
}

// Large devices
@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
			@content;
	}
}

// Extra large devices
@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
			@content;
	}
}

// Extra large devices
@mixin xxl {
	@media (min-width: #{$screen-xxl-min}) {
			@content;
	}
}

// Custom devices
@mixin rwd($screen) {
	@media (min-width:( $screen + 'px' )) {
			@content;
	}
}

@mixin label() {
	color: #fff;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.8px;
}

@mixin button(){
	background: linear-gradient(#FF930A, #F24B04);
	padding: 10px 20px;
	border-radius: 10px;
}

@mixin container($direction: row, $justify: center, $align: center) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
}

@mixin column($count : 3){
	display: flex;
	flex: 0 0 auto;
	width : percentage( calc(1 / $count) );
}

@mixin absolute-center {
	position: absolute;
	@include container;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

@mixin gradient($rotation){
	fill: linear-gradient($rotation, #002c7f 0%,#023492 25%, #043ba2 75%);
}