@use '../styles/config.scss';
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

h1 {
	font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 0px;
  display: block;
}

h2 {
	font-size: 45px;
	font-weight: 700;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
	@include config.sm {
			max-width: 540px;
	}
	@include config.md{
			max-width: 720px;
	}
	@include config.lg{
			max-width: 960px;
	}
	@include config.xl{
			max-width: 1140px;
	}
	@include config.xxl{
		max-width: 1320px;
	}
}

.scrolling-container{
		width: 100%;
		height: 100vh;
		scroll-snap-type: y mandatory;
		overflow-y: scroll;
		.snappy {
			display : flex;
			justify-content: center;
			align-items: center;
			scroll-snap-align: start;
			scroll-snap-stop: always;
			height: 100vh;
		}
}

.row {
  @include config.container($direction: column-reverse);
	padding: 15px;
	height: 100%;
  align-items: center;
	@include config.lg{
		flex-direction: row;
	}
}

.portfolio-section {
		min-height: 100%;
}

.main-section{
	min-height: 100%;
	&:first-child{
		margin-bottom: 110px;
	}
}

.flex{
	display: flex;
}

.flex-column{
	display: flex;
	flex-direction: column;
}

.pane-left{
	@include config.md {
		flex: 0 0 auto;
		width : 100%
	}
	@include config.lg {
			flex: 0 0 auto;
			width : 58.333%;
	}
}

.pane-right{
	@include config.md {
		flex: 0 0 auto;
		width : 100%
	}
	@include config.lg {
		flex: 0 0 auto;
		width: 41.667%;
	}
}

#particleBg {
  @include config.absolute-center;
	z-index: -10000;
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: repeating-linear-gradient(
      315deg,
      #00ffff2e 92%,
      #073aff00 100%
    ),
    repeating-radial-gradient(
      75% 75% at 238% 218%,
      #00ffff12 30%,
      #073aff14 39%
    ),
    radial-gradient(99% 99% at 109% 2%, #00c9ffff 0%, #073aff00 100%),
    radial-gradient(99% 99% at 21% 78%, #7b00ffff 0%, #073aff00 100%),
    radial-gradient(160% 154% at 711px -303px, #2000ffff 0%, #073affff 100%);
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

