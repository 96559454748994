nav {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;
}
.navbar {
	display: flex;
	flex-direction: column;
	.navitem {
		color : white;
		text-decoration: none;
	}
}