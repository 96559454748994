@use "../../../styles/config.scss";

$opacity: 0.5;
$border-glow: 10px;
$circle-dimensions: 60px;

.banner {
  @include config.container;
  margin-top: 100px;
  margin-bottom: 200px;
  padding: 0px;
	height: 100vh;
  @include config.lg {
    padding: 0px 0 100px 0;
  }
  .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(
      90.21deg,
      rgba(170, 54, 124, 0.5) -5.91%,
      rgba(74, 47, 189, 0.5) 111.58%
    );
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }
  h1 {
    font-size: 45px;
		text-align: center;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
		margin-top: 10px;
    display: block;
		@include config.lg{
			margin-top: 0px;
			font-size: 65px;
			text-align: left;
		}
  }
  h2 {
		font-size: 32px;
		text-align: center;
		margin-top: 5px;
    margin-bottom: 10px;
		@include config.lg{
			font-size: 45px;
			text-align: left;
			margin-top: 0px;
			margin-bottom: 20px;
		}
  }
  h3 {
		font-size: 22px;
		font-weight: 700;
		text-align: center;
		@include config.lg{
			font-size: 18px;
			text-align: left;
		}
  }
  p {
    color: #b8b8b8;
    font-size: 18px;
		text-align: center;
    letter-spacing: 0.8px;
    line-height: 1.5em;
		@include config.lg{
			width: 96%;
			text-align: left;
		}
  }

	.arrow-buttons{
		@include config.container($direction: column, $align: flex-start)
	}

  .button-top {
		margin-top: 20px;
		@include config.lg{
			margin-top: 30px;
		}
  }
  .button-bottom {
		margin-bottom: 10px;
		@include config.lg{
			margin-bottom: 40px;
		}
  }
  .bounce {
    animation: updown 3s linear infinite;
  }
}

.button-space {
	margin-top: 25px;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.title {
  margin-bottom: 5px;
	@include config.lg{
		margin-bottom: 20px;
	}
}

.wrapper {
  height: 820px;
  perspective: 1000px;
  padding: 0 15px;
  @include config.lg {
    height: 585px;
  }
  .card {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    .face {
      position: absolute;
      width: 100%;
			height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 10px;
			background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
    }
    .front {
      @include config.container;
    }
    .back {
      @include config.container;
      transform: rotateY(180deg);
    }
  }
  .card-flipped {
    transform: rotateY(180deg);
  }
}

.about-me {
	@include config.container($direction: column-reverse, $justify: flex-start, $align:flex-start);
	height: 100%;
	@include config.lg{
		flex-direction: column
	}
}

.pane-right {
  @include config.container;
  img {
    width: 50%;
    border-radius: 20px;
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.35);
    @include config.lg {
      width: 100%;
    }
  }
}

.split-pane {
  @include config.container;
  height: 100%;
  .pane {
    flex: 1;
    height: 100%;
  }
  .pane-left {
    @include config.container(column);
    flex: 2;
  }
  .pane-right {
    @include config.container;
    flex: 3;
  }
}

.circle {
  @include config.container;
  position: relative;
  width: 550px;
  height: 550px;
}
.circle::before {
  content: "";
  position: absolute;
  top: $circle-dimensions;
  left: $circle-dimensions;
  right: $circle-dimensions;
  bottom: $circle-dimensions;
  border-radius: 50%;
  border: $border-glow solid rgba($color: #fff, $alpha: 0.75);
  box-shadow: 0 0 50px #0f0;
  inset: 0 0 50px #0f0;
  animation: animate 5s linear infinite;
}

.circle img {
  width: 75%;
  height: 75%;
  border-radius: 50%;
}

#accomplishments {
	color: #b8b8b8;
	margin-left: 40px;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	> li {
		margin-bottom: 5px;
	}
}

@keyframes animate {
  0% {
    border: $border-glow solid rgba(111, 0, 255, $opacity);
    box-shadow: 0 0 50px rgb(111, 0, 255);
    inset: 0 0 40px rgb(111, 0, 255);
    filter: hue-rotate(0deg);
  }
  25% {
    border: $border-glow solid rgba(111, 0, 255, $opacity);
    box-shadow: 0 0 50px rgb(111, 0, 255);
    inset: 0 0 60px rgb(111, 0, 255);
  }
  50% {
    border: $border-glow solid rgba(111, 0, 255, $opacity);
    box-shadow: 0 0 50px rgb(111, 0, 255);
    inset: 0 0 80px rgb(111, 0, 255);
    filter: hue-rotate(45deg);
  }
  75% {
    border: $border-glow solid rgba(111, 0, 255, $opacity);
    box-shadow: 0 0 50px rgb(111, 0, 255);
    inset: 0 0 60px rgb(111, 0, 255);
  }
  100% {
    border: $border-glow solid rgba(111, 0, 255, $opacity);
    box-shadow: 0 0 50px rgb(111, 0, 255);
    inset: 0 0 40px rgb(111, 0, 255);
    filter: hue-rotate(0deg);
  }
}
