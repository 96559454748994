@use '../../../styles/config.scss';
.swiper-container{
	max-width: 360px;
	height: 600px;
	padding-top: 50px;
	padding-bottom: 50px;
}
.swiper-slider{
	@include config.container
}
.swiper-3d {
	.swiper-slide-shadow-left{
		background-image: none;
	}
	.swiper-slide-shadow-right{
		background-image: none;
	}
}

.swiper-pagination-horizontal {
	top: 100% !important;
	bottom: 0;
}