@use "../../../../styles/config.scss";
$card-max-height: 450px;
$img-shrink-height: 100px;
$content-shrink-time: 0.2s;

.project-card {
  width: 100%;
  height: $card-max-height;
  @include config.md {
    width: 430px;
  }
  @include config.lg {
    position: relative;
    height: 315px;
  }
}
.project-innercard {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  transition: 0.6s;
  height: 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0px 35px 80px rgpa(0, 0, 0, 0.15);
  z-index: 0;
  &:hover {
    height: $card-max-height;
    z-index: 100;
    border-radius: 20px;
    overflow: hidden;
    .imgContainer {
      height: $img-shrink-height;
      img {
        opacity: 0;
      }
      .project-header {
        top: 50%;
        opacity: 1;
      }
    }
    .project-content {
      height: ($card-max-height - $img-shrink-height);
      .project-details {
        opacity: 1;
      }
      .project-links {
        opacity: 1;
      }
    }
  }
  .imgContainer {
    @include config.container;
    width: 100%;
    height: 315px;
    // background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
    background: linear-gradient(
      190deg,
      rgba(74, 2, 79, 1) 0%,
      rgba(175, 0, 255, 1) 51%,
      rgba(255, 145, 0, 1) 100%
    );
    overflow: hidden;
    transition: 0.5s;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s;
      opacity: 1;
    }
    .project-header {
      text-align: center;
      transition: 0.2s ease-in-out;
      opacity: 0;
      width: 100%;
      h4 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0.8px;
        line-height: 1.1em;
      }
      span {
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.8px;
      }
    }
  }
  .project-content {
    @include config.container($direction: column, $align: flex-end);
    width: 100%;
    height: 0;
    overflow: hidden;
    .project-details {
      @include config.container($direction: column, $justify: space-between);
      height: 100%;
      padding: 20px;
      transition: $content-shrink-time;
      opacity: 0;
      .wave-divider {
        position: absolute;
        top: -20px;
      }
      p {
        width: 100%;
        margin: 0;
        color: black;
      }
    }
    .project-links {
      @include config.container($justify: space-around);
      transition: $content-shrink-time;
      opacity: 0;
      width: 100%;
			z-index: 1;
    }
  }
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
  height: 200vh;
}
